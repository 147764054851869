// Scott Park Group

export default {
  settings: {
    disableIframePostMessage: false,
    ctaWhiteBg: "true",
  },
  content: {
    general: {
      clientName: `Scott Park Group`,
      healthcheckDevLink: "https://dev.moneyfit.app/scott-park-group/",
    },
    heroIntro:
      "A company funded benefit provided by Gallagher, our Benefits Consultant, that encourages you to engage more with your money and help you face your financial future with confidence.",
    modellers: [
      {
        title: "BUDGETING",
        description: `See the potential of Money Leaks`,
        type: "budgeting",
        url: "https://lifetime.gbsau.com/gallagher-gbs/small-change/",
        categoryPic: "pie_w.svg",
        bgImage: "budgeting",
      },
      {
        title: "DEBT MANAGEMENT",
        description: "Debt Modeller",
        type: "debt",
        url: "https://lifetime.gbsau.com/gallagher/debt-modeller/",
        categoryPic: "debt_w.svg",
        bgImage: "debt",
      },
      {
        title: "SUPERANNUATION",
        description: "Understanding super",
        type: "savings",
        url: "https://lifetime.gbsau.com/gallagher/supermodeller/",
        categoryPic: "savings_w.svg",
        bgImage: "understanding_super",
      },
      {
        title: "SUPERANNUATION",
        description: "Scott Park Group Super plan",
        type: "savings",
        url: "https://player.vimeo.com/video/827882984",
        categoryPic: "savings_w.svg",
        bgImage: "savings",
      },
      // {
      //   title: "INSURANCE",
      //   description: "Insurance Needs",
      //   type: "budgeting",
      //   url: "https://www.australiansuper.com/",
      //   categoryPic: "pie_w.svg",
      //   bgImage: "insurance",
      // },
      {
        heading: "MoneyFit Healthcheck",
        subheading: "Financial Wellbeing",
        description:
          "Uncover your financial tendencies. Explore your unique approach to money management and financial decisionmaking. MoneyFit can help you achieve financial wellbeing. It aims to empower you to feel confident and in control of your finances.",
        link: "LAUNCH MONEYFIT HEALTH CHECK",
        categoryPic: "owl.png",
        moneyFit: true,
        type: "budgeting",
      },
    ],
  },

  analytics: {
    googleTrackingId: "G-REJYV6QT32",
    enableClarity: true,
    clarityTrackingId: "mt8x67ieed",
  },
};
